html,body{
   margin: 0 !important;
   height: 100vh !important;
}
#root{
  min-height: 100vh;
}
.main-container{
  min-height: 100vh;
  overflow:auto;
}
/*HOMEPAGE CSS*/
.header-col{
  padding-left:33px;
}
.pb-row{
  background-color: #a42f2c;
}

/*END DEATH OF ME IMG*/
.sponsor {
  background-color: #a42f2c;
}
/*END HOMEPAGE CSS*/
/*ASK COMPONENT CSS*/
.question-container {
  padding-top: 50px;
  padding-bottom: 70px;
  width: 100%;
  height: 100%;
}

#input-question{
  padding-inline-start: 15px;
  padding-inline-end: 90px;
  position:relative;
}
#input-question::placeholder {
  color: white !important;
  opacity: 1 !important;
  font-weight: normal !important;
}
.form{
  position: relative;
}

.form .fa-search{

  position: absolute;
  top:20px;
  left: 20px;
  color: #9ca3af;

}

.form span{
  position: absolute;
  right: 17px;
  top: 13px;
  padding-bottom: 10px;
}

.left-span{
  padding-left: 7px;
  background-color: #a42f2c;
}

.left-pan-i{
 
 padding-left: 10px;
}

.form-input{

  height: 55px;
  text-indent: 33px;
  border-radius: 10px;
}

.search-btn{
  background-color: #a42f2c;
  border: none;
}

.search-gpt{
  background-color: #a42f2c;
  border-radius: 45px;
  color:white;
}

.search-gpt:focus{
  background-color: #a42f2c;
  border-radius: 45px;
  color:white;
}


/*END ASK COMPONENT CSS*/
/*LOAD ICON CSS*/
.img-load-icon{
  animation: rotation 2s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(359deg);
  }
  to {
    transform: rotate(0deg);
  }
}
/*END LOAD ICON CSS*/
/*CARD SECTION AND CARD CSS*/

.form-select{
  background: #a42f2c url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'><path fill='%23ffffff' d='M2 0L0 2h4zm0 5L0 3h4z'/></svg>") right .75rem center/8px 10px no-repeat!important;
}

.card-reply-row{
  padding-bottom: 30px;
  padding-top:10px;
}
.card-column {
  padding: 50px;
}

.generic-card {
  background-color: white;
  border-radius: 30px !important;
}

.generic-card-header {
  border: none;
  background-color: white;
  border-radius: 30px !important;
  padding-top: 35px;
}

.card-name {
  color: #a42f2c;
  font-weight: bold;
}

.card-container {
  background-color: #a42f2c;
}

.card-input {
  background-color: #a42f2c;
  border-radius: 45px;
}

.card-input::placeholder {
  color: white !important;
  opacity: 1 !important;
  font-weight: normal !important;
}
.card-input:focus {
  background-color: #a42f2c;
}

.card-input:focus::placeholder {
  color: transparent !important;
}

.input-row-top {
  padding-top: 30px;
  padding-bottom: 30px;
}

.input-row-bottom {
  padding-bottom: 40px !important;
}

.reply-row {
  padding-bottom: 50px;
  padding-top:75px;
}
.reply-column{
  padding-bottom: 50px;
}
.get-button {
  border: none;
  border-radius: 45px;
  background-color: #a42f2c;
  color: white;
  width: 125px;
}
.get-button:hover{
    background-color: white;
    color:#a42f2c;
    border-radius: 45px;
    border-color: #a42f2c;
    border: 1px solid;
    
}
.get-button:active:focus{
  border: none;
  border-radius: 45px;
  background-color: #a42f2c;
  color: white;
  width: 125px;
}

/* END CARD SECTION AND CARD CSS*/
/*FOOTER/NAVBAR CSS*/
.footer-link{
  text-decoration: none;
  color:white;
}
.footer-link:hover{
  color:white;
}
.stuck-element{
  background-color: #a42f2c;
  color: white;
}

.nav-page-link:hover{
  text-decoration: underline;
}

.navbar-toggler{
  border: none;
}
.navbar-toggler:focus{
  box-shadow:none;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
/*END FOOTER CSS*/

/*IMAGE CSS*/
.page-break-wrapper img{
  width: 100%;
  height: auto;
}

.search-logo-img {
  width: 100%;
  height: auto;
}

@media (min-width: 300px){
  .search-logo-img{
    width:250px;
  }
}

@media (min-width: 768px) {
  .search-logo-img {
      width: 250px;
  }
}

@media (min-width: 992px) {
  .search-logo-img {
      width: 200px;
  }
}

@media (min-width: 1200px) {
  .search-logo-img {
      width: 450px;
  }
}

/*END IMAGE CSS*/

/*LEAGUE MODAL CSS*/
.league{
  padding-left: 0 !important;
  padding-right: 0 !important;
  overflow:auto;
}
/*END LEAGUE MODAL CSS*/
/*PRODUCT MODAL CSS*/



.product-img{
  width:225px;
  height:auto;
}

.product-close-fa:hover{
  cursor:pointer;
}

/*START ABOUT FORM CSS*/
.about-form-field{
  border-top: none;
  border-right: none;
  border-left:none;
  border-radius: 0;
  border-bottom-color: #a42f2c !important;
  width: 100%;
}

.about-form-field:focus{
  outline: none;
  box-shadow: 0 8px 6px -6px #a42f2c;
  background-color: white;
}

.about-form-field::placeholder{
  font-family: 'Bradley Hand, cursive';
  font-size: 16px;
}
/*START ABOUT FORM CSS*/



/*END PRODUCT MODAL CSS*/
.card-btn-col{
  position:absolute;
  bottom:0;
  padding-bottom: 10px;
}
.row{
  padding-right: 0px !important;
  padding-left: 0px !important;
  --bs-gutter-x:0 !important;
}

